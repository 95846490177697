<template>
  <div class="info" id="watermark">
    <top-bar :title="'返程人员'" :left="true"></top-bar>
    <van-cell-group>
      <van-cell class="info-title">
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">基本信息</span>
        </template>
      </van-cell>
      <van-field readonly v-model="dataForm.subareaName" label="居住小区" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.houseName" label="居住房屋" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.userName" label="姓名" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.sex == '1'? '男' : '女'" label="性别" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.birthday" label="出生日期" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.mobile" label="手机号码" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.idNumber" label="身份证号" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.temperature + '°'" label="体温" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.returnCity" label="返回城市" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.backDate" label="返回日期" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.returnTypeStr" label="回程方式" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.symptoms == 1? '身体正常,无相关疾病症状' : '有咳嗽等症状'" label="身体状态" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.isIsolation == '1' ? '已被隔离' : '未隔离'" label="是否隔离" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.hasCheck == 0? '未检测' : '已检测'" label="是否检测核酸" placeholder="未知" input-align="right"/>
    </van-cell-group>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {getBirthdayFromIdCard} from '@/utils/utils'
import {getImageStream} from '@/utils/index'

export default {
  components: {
    topBar
  },
  data() {
    return {
      infoVisible: false,
      dataForm: {
      },
      health: {
        heat: '1',
        contact: '1',
        temperature: ''
      },
      id: 0,
      repairList: [],
      visitList: []
    }
  },
  methods: {
    init() {
      this.id = this.$route.query.id
      this.$http({
        url: this.$http.adornUrl('/wxapp/epidemic/return/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.id
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataForm = data.appEpidemicReturn
          this.dataForm.birthday = getBirthdayFromIdCard(this.dataForm.idNumber)
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    }
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.init()
  }
}
</script>
<style scoped>
.info >>> .van-cell__title {
  font-weight: 600;
}
.info >>> .van-field__control {
  color: #999999;
}
.list-info {
  padding-bottom: 20px !important;
}
.list-item {
  border: 1px solid #eee;
}
.list-item:nth-child(2) {
  margin-top: 20px !important;
}
.left >>> .van-field__control {
  text-align: left !important;
}
</style>
